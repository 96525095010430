import { Trans, useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { getAvailableNamespaces } from "../../../translations";
import { useState } from "react";

const useStyles = createUseStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "32px",
        background: "#FEB80C",
        color: "#353535",
    },
    accordionContainer: {
        width: "100%",
        maxWidth: "1100px",
        overflow: "hidden",
        "& > h1": {
            color: "#151515",
            fontFamily: "Inter",
            fontSize: "36px",
            fontWeight: 200,
            lineHeight: "120%",
            letterSpacing: "1.8px",
            maxWidth: "300px",
            marginBottom: "24px",
            textAlign: "left",
            alignSelf: "start",
        },
    },
    accordionItem: {
        borderBottom: "0.5px solid #E9A500",
    },
    accordionHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px 0px",
        cursor: "pointer",
        color: "#353535",
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "120%",
        letterSpacing: "2px",
    },
    accordionContent: {
        color: "#353535",
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "120%",
        letterSpacing: "1.4px",
        height: "0",
        overflow: "hidden",
        transition: "height 0.3s ease-in-out, padding 0.3s ease-in-out",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        "&.open": {
            height: "fit-content",
            padding: "15px 0px",
        },
    },
    accordionIcon: {
        marginRight: "6px",
        fontSize: "14px",
        width: "16px",
        height: "16px",
        border: "solid #353535",
        borderWidth: "0 2px 2px 0",
        transform: "rotate(45deg)",
        transition: "transform 0.3s ease-in-out",
        "&.open": {
            transform: "rotate(-135deg)",
        },
    },
    "@media (max-width: 768px)": {
        accordionHeader: {
            fontSize: "14px",
        },
        accordionContent: {
            fontSize: "14px",
        },
        accordionIcon: {
            fontSize: "8px",
            width: "8px",
            height: "8px",
        },
    },
}));

const accordionData = [
    {
        title: "welcomeRookiePage.answers_section.what_is_morgis.question",
        content: "welcomeRookiePage.answers_section.what_is_morgis.answer",
    },
    {
        title: "welcomeRookiePage.answers_section.is_morgis_a_good_fit_for_me.question",
        content:
            "welcomeRookiePage.answers_section.is_morgis_a_good_fit_for_me.answer",
    },
    {
        title: "welcomeRookiePage.answers_section.how_can_i_monetize_with_morgis.question",
        content:
            "welcomeRookiePage.answers_section.how_can_i_monetize_with_morgis.answer",
    },
    {
        title: "welcomeRookiePage.answers_section.what_type_of_content_can_i_sell.question",
        content:
            "welcomeRookiePage.answers_section.what_type_of_content_can_i_sell.answer",
    },
    {
        title: "welcomeRookiePage.answers_section.how_do_i_get_paid_in_morgis.question",
        content:
            "welcomeRookiePage.answers_section.how_do_i_get_paid_in_morgis.answer",
    },
    {
        title: "welcomeRookiePage.answers_section.what_is_morgis_mission.question",
        content:
            "welcomeRookiePage.answers_section.what_is_morgis_mission.answer",
    },
    {
        title: "welcomeRookiePage.answers_section.what_can_i_do_with_my_monthly_subscription_communities.question",
        content:
            "welcomeRookiePage.answers_section.what_can_i_do_with_my_monthly_subscription_communities.answer",
    },
    {
        title: "welcomeRookiePage.answers_section.what_is_the_revenue_share_on_morgis.question",
        content:
            "welcomeRookiePage.answers_section.what_is_the_revenue_share_on_morgis.answer",
    },
];

const AnswersSection = () => {
    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });

    const [openIndex, setOpenIndex] = useState(null);

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <section className={classes.container}>
            <div className={classes.accordionContainer}>
                <h1>{t("welcomeRookiePage.answers_section.title_section")}</h1>
                {accordionData.map((item, index) => (
                    <div className={classes.accordionItem} key={index}>
                        <div
                            className={classes.accordionHeader}
                            onClick={() => toggleAccordion(index)}
                        >
                            {t(item.title)}
                            <span
                                className={`${classes.accordionIcon} ${
                                    openIndex === index ? "open" : ""
                                }`}
                            />
                        </div>
                        <div
                            className={`${classes.accordionContent} ${
                                openIndex === index ? "open" : ""
                            }`}
                        >
                            <Trans
                                t={t}
                                i18nKey={item.content}
                                components={{ span: <span /> }}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};
export default AnswersSection;
