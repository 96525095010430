import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { createUseStyles } from "react-jss";
import cx from "classnames";
import { BackIcon, CloseIcon } from "../assets/icons/icons.js";
import PropTypes from "prop-types";

const useStyles = createUseStyles((theme) => ({
    overlay: {
        // fullscreen
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: ({ contextMode }) =>
            contextMode ? "rgba(0,0,0,0)" : "rgba(0,0,0,0.5)",
        display: "grid",
        alignContent: "center",
        justifyContent: "center",
        zIndex: ({ overlayZIndex }) => (overlayZIndex ? overlayZIndex : 999),
    },

    root: {
        position: "fixed",
        "min-width": ({ minWidth }) => minWidth || undefined,
        "min-height": ({ minHeight }) => minHeight || undefined,
        "max-width": ({ maxWidth }) => maxWidth || undefined,
        "max-height": ({ maxHeight }) => maxHeight || undefined,
        width: ({ width }) => width || undefined,
        height: ({ height }) => height || undefined,
        top: ({ y }) => (y ? y : "50%"),
        left: ({ x }) => (x ? x : "50%"),
        transform: ({ x }) => (x ? "unset" : "translate(-50%,-50%)"),
        backgroundColor: theme.colors.white,
        overflow: "visible",
        zIndex: 999,
        borderRadius: 16,
        display: "grid",
        gridTemplateRows: "0fr 1fr",
        [theme.s]: {
            position: "fixed",
            top: "0",
            left: "0",
            transform: "none",
            zIndex: 999,
        },
    },

    header: {
        display: ({ contextMode }) => (contextMode ? "none" : "grid"),
        gridTemplateColumns: ({ withHeader }) =>
            withHeader
                ? `${theme.spacing * 4}px 1fr ${theme.spacing * 4}px`
                : "1fr 1fr",
        justifyItems: ({ withHeader }) => (withHeader ? "center" : "end"),
        alignItems: "center",
        padding: theme.spacing * 2,
        position: "relative",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        "& h4": {
            margin: 0,
            textAlign: "center",
        },
        "& svg": {
            justifySelf: "end",
        },
    },

    body: {
        padding: ({ contextMode }) =>
            contextMode ? 0 : `${theme.spacing * 3}px`,
        overflowY: "auto",
    },

    closeIcon: {
        cursor: "pointer",
    },

    backIcon: {
        cursor: "pointer",
    },
}));

const Popover = ({
    children,
    className,
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,
    width,
    height,
    overlay = true,
    title,
    onClose,
    onBack,
    hideCloseButton = false,
    withHeader,
    x,
    y,
    contextMode,
    bodyClassName,
    overlayZIndex = 999,
}) => {
    const classes = useStyles({
        minWidth,
        minHeight,
        maxWidth,
        maxHeight,
        width,
        height,
        withHeader,
        x,
        y,
        contextMode,
        overlayZIndex,
    });

    useEffect(() => {
        window.document.body.style["overflow-y"] = "hidden"; // lock body scroll
        return () => (window.document.body.style["overflow-y"] = "auto"); // unlock body scroll;
    }, []);

    const render = (
        <>
            {overlay && <div className={classes.overlay} onClick={onClose} />}

            <section className={cx(classes.root, className)}>
                <header className={classes.header}>
                    <div>
                        {onBack && (
                            <BackIcon
                                onClick={onBack}
                                className={classes.backIcon}
                            />
                        )}
                    </div>
                    {withHeader && <h4>{title}</h4>}
                    <div>
                        {!hideCloseButton && (
                            <CloseIcon
                                data-color
                                onClick={onClose}
                                className={classes.closeIcon}
                            />
                        )}
                    </div>
                </header>
                <div className={cx(classes.body, bodyClassName)}>
                    {children}
                </div>
            </section>
        </>
    );

    return createPortal(render, document.body);
};

Popover.propTypes = {
    className: PropTypes.string,
    bodyClassName: PropTypes.string,
    minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    overlay: PropTypes.bool,
    title: PropTypes.string,
    onBack: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    hideCloseButton: PropTypes.bool,
    withHeader: PropTypes.bool,
    x: PropTypes.number,
    y: PropTypes.number,
    contextMode: PropTypes.bool,
};

export default Popover;
