import Tippy from "@tippyjs/react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
    InfoLightCircleIcon,
    MicromorgiIcon,
} from "../../../../../assets/icons/icons";
import Button from "../../../../../components/Button";
import { getAvailableNamespaces } from "../../../../../translations";

const useStyles = createUseStyles((theme) => ({
    root: {
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 10px 0px",
        borderRadius: "14px",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
    },
    achievementsTitle: {
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "28px",
        margin: "16px 0 16px 0",
    },
    imageContent: {
        width: "100%",
        paddingBottom: theme.spacing * 1.25,
        height: "100px",
        borderRadius: 4,
        background: "linear-gradient(135deg, #808080, #3d3d3d)",
        "& img": {
            width: "100%",
            borderRadius: 4,
            objectFit: "cover",
            maxHeight: "100px",
        },
    },
    infoContainer: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        fontSize: "12px",
    },
    tooltipBox: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing / 2,
    },
    infoBox: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing / 2,
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: "#8649D1",
    },
    button: {
        width: "100%",
        maxWidth: "100%",
        display: "flex",
        "& span": {
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
        },
    },
    tooltipTrigger: {
        "& svg": {
            cursor: "pointer",
            "& path": {
                opacity: 1,
            },
        },
    },
    tippy: {
        padding: "4px",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        width: 315,
        maxHeight: 66,
        borderRadius: 4,
        borderRadius: "14px",
        zIndex: "3",
    },
    subTitle: {
        fontSize: 20,
        textAlign: "center",
    },
}));

export const MembershipPlans = ({
    inModal = true,
    content,
    onSubmit = () => {},
    rookieName,
}) => {
    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });
    const hasDefaultImage =
        content?.group?.url?.includes("default_group_image.png") || false;
    return (
        <div>
            {inModal && (
                <div className={classes.subTitle}>
                    {t("rookie_profile_page.choose_support_modal.sub_title", {
                        ROOKIE_NAME: rookieName,
                    })}
                </div>
            )}
            <div>
                <div className={classes.achievementsTitle}>
                    {t(
                        "rookie_profile_page.choose_support_modal.rookie_membership_plans",
                        { ROOKIE_NAME: rookieName }
                    )}
                </div>
                <div className={classes.root}>
                    <div
                        className={classes.imageContent}
                        style={
                            hasDefaultImage
                                ? {
                                      background: "none",
                                      border: "1px solid black",
                                  }
                                : {}
                        }
                    >
                        {!!content?.group?.url && (
                            <img
                                src={content.group.url}
                                style={{
                                    objectFit: hasDefaultImage
                                        ? "contain"
                                        : "cover",
                                    height: hasDefaultImage ? "98px" : "100px",
                                }}
                            />
                        )}
                    </div>
                    <div className={classes.infoContainer}>
                        <div className={classes.infoBox}>
                            <div className={classes.circle} />
                            {t("rookie_profile_page.membership.surprises", {
                                SURPRISES_COUNT: content?.surprises_count,
                            })}
                        </div>
                        <div className={classes.tooltipBox}>
                            <div className={classes.infoBox}>
                                <div className={classes.circle} />
                                {content.group.name}
                            </div>
                            <div>
                                {!!content.group?.messages?.length && (
                                    <Tippy
                                        theme="light"
                                        placement={"bottom"}
                                        trigger="click"
                                        inlinePositioning={true}
                                        interactive={true}
                                        className={classes.tippy}
                                        content={
                                            content.group?.messages?.[0] ? (
                                                <span>
                                                    {
                                                        content.group
                                                            ?.messages[0]
                                                            .message
                                                    }
                                                </span>
                                            ) : undefined
                                        }
                                    >
                                        <span
                                            className={classes.tooltipTrigger}
                                        >
                                            <InfoLightCircleIcon
                                                width="10px"
                                                height="10px"
                                            />
                                        </span>
                                    </Tippy>
                                )}
                            </div>
                        </div>
                    </div>
                    <Button
                        data-primary
                        className={classes.button}
                        onClick={onSubmit}
                    >
                        {t(
                            "rookie_profile_page.choose_support_modal.see_all_plans"
                        )}
                        <MicromorgiIcon />
                    </Button>
                </div>
            </div>
        </div>
    );
};
