import { useTranslation } from "react-i18next"
import { createUseStyles } from "react-jss"
import Button from "../../../components/Button"
import { getAvailableNamespaces } from "../../../translations"
import { PARAM_USER_GROUP } from "../../../utilities/constants"
import { APP_ROUTE_SIGNUP_ROOKIE_SOCIAL } from "../../../utilities/constants/appRouteNames"
import { getUserGroup } from "../../../utilities/constants/user"
import { getAppLink, redirectToExternalLink, remapURLSearchParams } from "../../../utilities/utils"
import advantagesHeroMob from '../assets/whyMorgisSection/advantagesHeroMob.png'

const useStyles = createUseStyles(() => ({
    container: {
        background: "linear-gradient(180deg, #FFF 0%, #E7D3FF 100%)",
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
        padding: [16, 8]
    },
    advantagesHeroMob: {
        maxWidth: 592,
        maxHeight: 391,
        width: '100%',
        height: '100%'
    },
    title: {
        color: '#161616',
        textAlign: 'center',
        fontSize: 'clamp(32px, 3vw, 40px)',
        fontWeight: 700,
        lineHeight: '120%', /* 48px */
        letterSpacing: '5px',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: [0, 8],
        alignItems: 'center'
    },
    contentList: {
        maxWidth: 908,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 32,
        padding: [32, 0],
        "& > div": {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            maxWidth: 454,
            width: '100%',
            alignSelf: 'flex-start',
            "& > h3, & > p": {
                margin: 0,
            },
        },
        "& > div:nth-child(2n)": {
            alignSelf: 'flex-end',
            alignItems: 'flex-end',
            textAlign: 'end'
        }
    },
    contentTitle: {
        color: 'rgba(83, 41, 152, 0.80)',
        fontSize: 'clamp(24px, 3vw, 32px)',
        fontWeight: 700,
        lineHeight: '120%', /* 38.4px */
    },
    contentDescription: {
        color: 'rgba(112, 92, 144, 0.80)',
        fontSize: 'clamp(16px, 3vw, 20px)',
        fontWeight: 400,
        lineHeight: '120%', /* 24px */
    },
    CTA: {
        width: '100%',
        height: '100%',
        maxHeight: 72,
        maxWidth: 454,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        "& span": {
            fontSize: 'clamp(16px, 5vw, 24px)',
            fontWeight: 500,
            letterSpacing: '1px',
            textWrap: 'nowrap'
        }
    },
    '@media (max-width: 576px)': {
        contentList: {
            "& > div": {
                alignSelf: 'unset',
            }
        },
        CTA: {
            maxWidth: '250px',
        }
    }
}))
const WhyMorgisSection = () => {
    const classes = useStyles()
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });
    const redirectToSignUp = () => {
        redirectToExternalLink(
            getAppLink(APP_ROUTE_SIGNUP_ROOKIE_SOCIAL, [
                ...remapURLSearchParams(location.search),
                {
                    name: PARAM_USER_GROUP,
                    value: getUserGroup(),
                },
            ]))
    }
    return <section className={classes.container}>
        <img className={classes.advantagesHeroMob} src={advantagesHeroMob} />
        <div className={classes.content}>
            <h2 className={classes.title}>{t('welcomeRookiePage.whyMorgisSection.title')}</h2>
            <div className={classes.contentList}>
                <div>
                    <h3 className={classes.contentTitle}>
                        {t('welcomeRookiePage.whyMorgisSection.contentList.content1.title')}
                    </h3>
                    <p className={classes.contentDescription}>
                        {t('welcomeRookiePage.whyMorgisSection.contentList.content1.description')}
                    </p>
                </div>
                <div>
                    <h3 className={classes.contentTitle}>
                        {t('welcomeRookiePage.whyMorgisSection.contentList.content2.title')}
                    </h3>
                    <p className={classes.contentDescription}>
                        {t('welcomeRookiePage.whyMorgisSection.contentList.content2.description')}
                    </p>
                </div>
            </div>

        </div>
        <Button data-primary className={classes.CTA} onClick={redirectToSignUp}>
            {t('welcomeRookiePage.whyMorgisSection.CTA')}
        </Button>
    </section>
}
export default WhyMorgisSection