import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Button from "../../../components/Button";
import { useViewportSize } from "../../../hooks/useViewportSize";
import { getAvailableNamespaces } from "../../../translations";
import { PARAM_USER_GROUP } from "../../../utilities/constants";
import { APP_ROUTE_SIGNUP_ROOKIE_SOCIAL } from "../../../utilities/constants/appRouteNames";
import { getUserGroup, ROOKIE_ROLES } from "../../../utilities/constants/user";
import {
    getAppLink,
    redirectToExternalLink,
    remapURLSearchParams,
} from "../../../utilities/utils";

const useStyles = createUseStyles({
    container: {
        maxWidth: "100%",
        width: "100%",
        height: "calc(100vh - 112px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 8,
    },
    content: {
        marginRight: "30vw",
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        maxWidth: 460,
        width: "100%",
        position: "relative",
        "& h1": {
            lineHeight: "57.18px",
            letterSpacing: "0.1em",
            textUnderlinePosition: "from-font",
            textDecorationSkipInk: "none",
            margin: 0,
        },
    },
    description: {
        color: "#FFFFFF",
        fontWeight: 300,
        fontSize: "clamp(36px, 5vw, 47px)",
    },
    expertise: {
        color: "#8649D1",
        fontSize: "clamp(38px, 3vw, 78px)",
        fontWeight: 700,
        textTransform: "uppercase",
    },
    CTA_wrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "12px",
    },
    CTA: {
        fontSize: "clamp(18px, 5vw, 24px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        maxWidth: "unset",
        border: "none !important",
    },
    "@media (max-width: 576px)": {
        container: {
            height: "calc(100vh - 64px)",
            alignItems: "flex-end",
            padding: [24, 8],
        },
        content: {
            marginRight: "0",
            maxWidth: 320,
            paddingBottom: "70px",
        },
        CTA: {
            width: "250px",
        },
    },
});

const mobileWidth = 576 / 100;

const BuildCommunitySection = ({ role = "main" }) => {
    const classes = useStyles();
    const { vw } = useViewportSize();
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });
    const defaultBackgroundPath = (name, isMobile) =>
        require(`../assets/buildCommunitySection/${role}/${name}${
            isMobile ? "_mobile" : ""
        }.jpg`);
    const [isMobile, setIsMobile] = useState(vw <= mobileWidth);
    const [currentSlide, setCurrentSlide] = useState(0);

    const redirectToSignUp = () => {
        redirectToExternalLink(
            getAppLink(APP_ROUTE_SIGNUP_ROOKIE_SOCIAL, [
                ...remapURLSearchParams(location.search),
                {
                    name: PARAM_USER_GROUP,
                    value: getUserGroup(),
                },
            ])
        );
    };
    useEffect(() => {
        setIsMobile(vw <= mobileWidth);
    }, [vw]);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentSlide((prev) =>
                prev + 1 < expertiseMap.length ? prev + 1 : 0
            );
        }, 5000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    const expertiseMap = {
        main: ["attraction", "expertise", "influence", "skills"],
        [ROOKIE_ROLES.ARTISTS]: ["groove", "art", "creativity", "influence"],
        [ROOKIE_ROLES.ATHLETE]: [
            "discipline",
            "dedication",
            "endurance",
            "tenacity",
        ],
        [ROOKIE_ROLES.ENTREPRENEURS]: [
            "courage",
            "expertise",
            "experience",
            "vision",
        ],
        [ROOKIE_ROLES.INFLUENCER]: [
            "influence",
            "authenticity",
            "innovation",
            "connection",
        ],
        [ROOKIE_ROLES.LICENSED]: [
            "professionalism",
            "knowledge",
            "dedication",
            "excellence",
        ],
        [ROOKIE_ROLES.MODELS]: ["beauty", "fashion", "style", "authenticity"],
        [ROOKIE_ROLES.TEACHERS]: [
            "knowledge",
            "experience",
            "wisdom",
            "expertise",
        ],
    }[role];

    return (
        <section
            style={{
                backgroundImage: `url(${defaultBackgroundPath(
                    expertiseMap[currentSlide],
                    isMobile
                )})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: isMobile ? "unset" : "center",
            }}
            className={classes.container}
        >
            <div className={classes.content}>
                <h1 className={classes.description}>
                    <Trans
                        t={t}
                        i18nKey={
                            "welcomeRookiePage.build_community_section.description"
                        }
                    />
                </h1>
                <h1 className={classes.expertise}>
                    {t(
                        `welcomeRookiePage.build_community_section.expertise.${expertiseMap[currentSlide]}`
                    )}
                </h1>
                <div className={classes.CTA_wrapper}>
                    <Button
                        data-primary
                        className={classes.CTA}
                        onClick={redirectToSignUp}
                    >
                        {t("welcomeRookiePage.build_community_section.CTA")}
                    </Button>
                </div>
            </div>
        </section>
    );
};
export default BuildCommunitySection;
