import cx from 'classnames';
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Slider from "react-slick";
import Button from '../../../components/Button';
import { getAvailableNamespaces } from "../../../translations";
import { PARAM_USER_GROUP } from "../../../utilities/constants";
import { APP_ROUTE_SIGNUP_ROOKIE_SOCIAL } from "../../../utilities/constants/appRouteNames";
import { getUserGroup } from "../../../utilities/constants/user";
import { getAppLink, redirectToExternalLink, remapURLSearchParams } from "../../../utilities/utils";

const useStyles = createUseStyles((theme) => ({
    container: {
        display: 'flex',
        padding: [40, 0],
        flexDirection: 'column',
        alignItems: 'center',
        gap: 32,
        background: 'linear-gradient(180deg, #8649D1 0%, #7200FF 100%)',
        maxWidth: '100%',
    },
    titleWrapper: {
        color: '#FFF',
        fontSize: 'clamp(28px, 5vw, 72px)',
        lineHeight: '110%',
        display: 'flex',
        flexDirection: 'column',
        gap: 24
    },
    title: {
        textAlign: 'center',
        fontWeight: 700,
        margin: 0,
    },
    subtitle: {
        fontWeight: 300,
    },
    animationSection: {
        width: '100%',
        maxWidth: '1112px',
        overflow: 'hidden',
        padding: '72px 0 24px',
        "& *": {
            minHeight: "0px",
            minWidth: "0px",
        },
        "& .slick-list": {
            overflow: 'visible',
            [theme.mUp]: {
                padding: '0px 50px 30px!important',
            },
        },
        "& .slick-track": {
            display: 'flex',
        },
    },
    slide: {
        width: '225px',

        "& img": {
            width: '100%',
            transition: 'all 0.5s ease-in-out'
        }
    },
    active: {
        "& img": {
            transform: 'scale(1.5)',
            position: 'relative',
            zIndex: 1
        }
    },
    inactive: {
        "& img": {
            filter: 'brightness(0.5)'
        }
    },
    CTA_wrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
    },
    CTA: {
        width: '100%',
        height: '100%',
        maxHeight: 72,
        maxWidth: 454,
        borderRadius: 21,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none !important',
        "& span": {
            background: "linear-gradient(0deg, #8649D1 0%, #8B6DE8 38.54%, #8F92FF 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontSize: 'clamp(16px, 5vw, 24px)',
            fontWeight: 500,
            letterSpacing: '1px',
            textWrap: 'nowrap'
        }
    },
    '@media (max-width: 576px)': {
        container: {
            padding: [32, 16]
        },
        titleWrapper: {
            alignSelf: 'flex-start',
            lineHeight: '120%',
        },
        slide: {
            "& img": {
                transform: 'unset',
            }
        },
        animationSection: {
            padding: 16,
        },
        title: {
            textAlign: 'unset',
        },
        CTA: {
            maxWidth: '250px',
            width: '100%'
        },
    }
}));


const getCorrectIndex = (index) => index > 3 ? index % 4 : index

const FindSupportersSection = ({ role = 'main' }) => {
    const classes = useStyles()
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });
    const contentMap = [
        `welcomeRookiePage.find_supporters.content.${role}.slide1`,
        `welcomeRookiePage.find_supporters.content.${role}.slide2`,
        `welcomeRookiePage.find_supporters.content.${role}.slide3`,
        `welcomeRookiePage.find_supporters.content.${role}.slide4`,
    ]

    const [currentSlide, setCurrentSlide] = useState(0)
    const getImage = (index) => require(`../assets/findSupportersSection/${role}/slide${index + 1}.gif`)
    const settings = {
        dots: false,
        infinite: true,
        speed: 2500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        draggable: false,
        swipeToSlide: false,
        centerMode: true,
        beforeChange: (_, nextIndex) => {
            setCurrentSlide(nextIndex)
        },
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    }

    const redirectToSignUp = () => {
        redirectToExternalLink(
            getAppLink(APP_ROUTE_SIGNUP_ROOKIE_SOCIAL, [
                ...remapURLSearchParams(location.search),
                {
                    name: PARAM_USER_GROUP,
                    value: getUserGroup(),
                },
            ]))
    }




    return <section className={classes.container}>
        <div className={classes.titleWrapper}>
            <Trans
                t={t}
                i18nKey={contentMap[getCorrectIndex(currentSlide)]}
                components={{ h1: <h1 className={classes.title} />, span: <span className={classes.subtitle} /> }}
            />
        </div>
        <div className={classes.animationSection}>
            <Slider {...settings}  >
                {[...contentMap, ...contentMap]?.map((_, index) => (
                    <div
                        key={index}
                        className={cx(classes.slide, index === currentSlide ? classes.active : classes.inactive)}
                    >
                        <img src={getImage(getCorrectIndex(index))} />
                    </div>

                ))}
            </Slider>
        </div>
        <div className={classes.CTA_wrapper}>
            <Button className={classes.CTA} onClick={redirectToSignUp}>
                {t('welcomeRookiePage.find_supporters.CTA')}
            </Button>
        </div>
    </section>
}
export default FindSupportersSection