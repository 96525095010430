import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { getAvailableNamespaces } from "../../../translations";
import bgDesktop from "../assets/beYourselfSection/bgDesktop.png";
import bgMobile from "../assets/beYourselfSection/bgMobile.png";
import logo from "../assets/beYourselfSection/logo.png";
import Button from "../../../components/Button";
import {
    getAppLink,
    redirectToExternalLink,
    remapURLSearchParams,
} from "../../../utilities/utils";
import {
    APP_ROUTE_LOGIN_ROOKIE,
    APP_ROUTE_SIGNUP_ROOKIE_SOCIAL,
} from "../../../utilities/constants/appRouteNames";
import { getUserGroup } from "../../../utilities/constants/user";
import { PARAM_USER_GROUP } from "../../../utilities/constants";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = createUseStyles((theme) => ({
    container: {
        background: "rgb(206 167 255)",
        padding: "8px 4px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    backgroundImage: {
        width: "100%",
        maxWidth: "1100px",
        height: "934px",
        backgroundImage: `url(${bgDesktop})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    contentWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "rgba(0, 0, 0, 0.7)",
        color: "#fff",
        padding: "76px",
        borderRadius: "62px",
        "& > img": {
            width: "190px",
            height: "190px",
        },
        "& > h1": {
            fontFamily: "Inter",
            fontSize: "38px",
            fontWeight: 400,
            lineHeight: "45px",
            letterSpacing: "-0.751px",
            margin: "28px 0px 25px",
        },
    },
    CTA: {
        width: "100%",
        height: "100%",
        maxHeight: 72,
        maxWidth: 454,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& span": {
            fontSize: "clamp(16px, 5vw, 24px)",
            fontWeight: 500,
            letterSpacing: "1px",
            textWrap: "nowrap",
        },
    },
    textAndLink: {
        display: "flex",
        fontSize: "22px",
        fontWeight: 500,
        lineHeight: "28.174px",
        letterSpacing: "-0.563px",
        marginTop: "12px",
        "& > span": {
            cursor: "pointer",
            color: "#fff",
            fontWeight: 700,
            marginLeft: "3px",
        },
    },
    "@media (max-width: 768px)": {
        backgroundImage: {
            maxWidth: "356px",
            height: "631px",
            backgroundImage: `url(${bgMobile})`,
        },
        contentWrapper: {
            padding: "46px",
            borderRadius: "40px",
            "& > img": {
                width: "122px",
                height: "122px",
            },
            "& > h1": {
                fontSize: "24px",
                fontWeight: 400,
                margin: "28px 0px 16px",
            },
        },
        textAndLink: {
            fontSize: "14px",
        },
    },
}));

const BeYourselfSection = () => {
    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });

    const redirectToSignUp = () => {
        redirectToExternalLink(
            getAppLink(APP_ROUTE_SIGNUP_ROOKIE_SOCIAL, [
                ...remapURLSearchParams(location.search),
                {
                    name: PARAM_USER_GROUP,
                    value: getUserGroup(),
                },
            ])
        );
    };
    const redirectToLogIn = () => {
        redirectToExternalLink(
            getAppLink(APP_ROUTE_LOGIN_ROOKIE, [
                ...remapURLSearchParams(location?.search),
            ])
        );
    };

    return (
        <section className={classes.container}>
            <div className={classes.backgroundImage}>
                <div className={classes.contentWrapper}>
                    <img src={logo} alt="morgis" />
                    <h1>{t("welcomeRookiePage.be_yourself_section.title")}</h1>
                    <Button
                        data-primary
                        className={classes.CTA}
                        onClick={redirectToSignUp}
                    >
                        {t("welcomeRookiePage.be_yourself_section.CTA")}
                    </Button>
                    <div className={classes.textAndLink}>
                        {t("welcomeRookiePage.be_yourself_section.text")}{" "}
                        <span onClick={redirectToLogIn}>
                            {t("welcomeRookiePage.be_yourself_section.link")}
                        </span>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default BeYourselfSection;
