import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Footer from '../components/Footer';
import Header from "../components/Header";
import PageMeta from "../components/PageMeta";
import { selectThemeMode } from "../store/settingSlice";
import { setPersona } from '../store/userSlice';
import { PARAM_PERSONA, ROUTE_HOME } from "../utilities/constants";
import { setUserGroup } from "../utilities/constants/user";

const useStyles = createUseStyles(theme => ({
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		transition: 'background 0.5s ease 0s',
		backgroundColor: ({ themeMode }) => themeMode === 'light' ? theme.colors.white : theme.colors.darkPurple
	},
	container: ({ fullWidth }) => ({
		...!fullWidth ? { composes: 'container' } : {},
		flex: 1
	})
}));

const PublicLayout = ({ children, meta, fullWidth }) => {

	const themeMode = useSelector(selectThemeMode)
	const classes = useStyles({ themeMode, fullWidth });
	const dispatch = useDispatch();
	const location = useLocation()
	const showFooter = [ROUTE_HOME].includes(location.pathname)
	useEffect(() => {
		setUserGroup();
		if (typeof window !== `undefined`) {
			const search = window.location.search;
			const params = new URLSearchParams(search);
			const param = params.get(PARAM_PERSONA);
			if (!!param && Number.isInteger(+param)) {
				dispatch(setPersona(+param))
			}
		}
	}, [])

	return (
		<div className={`${classes.wrapper}`}>
			<PageMeta {...meta} />
			<Header isLight={themeMode === 'light'} />
			<div className={classes.container}>
				{children}
			</div>
			{showFooter && <Footer />}
		</div>
	)
};


export default PublicLayout;
