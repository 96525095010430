import React from "react";
import { createUseStyles } from "react-jss";
import {
    useHistory,
    useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { ROUTE_LANDING_ROOKIE } from "../../utilities/constants";
import { ROOKIE_ROLES } from "../../utilities/constants/user";
import BuildCommunitySection from "./components/BuildCommunitySection";
import CreateConnectEarnSection from "./components/CreateConnectEarnSection";
import FindSupportersSection from "./components/FindSupportersSection";
import MoneyInMorgisSection from "./components/MoneyInMorgisSection";
import WhyMorgisSection from "./components/WhyMorgisSection";
import UserCommentsSection from "./components/UserCommentsSection";
import BeYourselfSection from "./components/BeYourselfSection";
import AnswersSection from "./components/AnswersSection";
import Footer from "./components/Footer";

const useStyles = createUseStyles({
    pageContainer: {},
});

const WelcomeRookiePage = () => {
    const classes = useStyles();
    const history = useHistory();
    const { role } = useParams();
    const roles = Object.values(ROOKIE_ROLES);
    const isCorrectRole = roles.includes(role);
    if (role && !isCorrectRole) history.push(ROUTE_LANDING_ROOKIE);

    return (
        <div className={classes.pageContainer}>
            <BuildCommunitySection role={role} />
            <FindSupportersSection role={role} />
            <CreateConnectEarnSection />
            <MoneyInMorgisSection />
            <WhyMorgisSection />
            <UserCommentsSection role={role} />
            <BeYourselfSection />
            <AnswersSection />
            <Footer />
        </div>
    );
};

export default WelcomeRookiePage;
