import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Button from "../../../components/Button";
import Popover from "../../../components/Popover";
import { getAvailableNamespaces } from "../../../translations";
import PlansList from "./components/PlansList/PlansList";
import MorgisCheckoutModal, {
    paymentTypes,
} from "../MorgisCheckoutModal/MorgisCheckoutModal";
import { useLockBodyScroll } from "../../../hooks/useLockBodyScroll";

const useStyles = createUseStyles({
    modalRoot: {
        width: "392px",
    },
    bodyClassName: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "0px 8px 8px",
        gap: "16px",
        margin: "0 auto",
    },

    description: {
        color: "#000",
        textAlign: "center",
        fontSize: "15px",
        lineHeight: "140%" /* 21px */,
        display: "flex",
        marginBottom: 8,
    },
    submit: {},
    "@media (max-width: 768px)": {
        modalRoot: {
            height: "100%",
            width: "100%",
            top: "0",
            left: "unset",
            transform: "none",
            borderRadius: 0,
        },
    },
});

const SubscriptionsPlansModal = ({
    hostName = "hostName1",
    onClose = () => {},
    rookieId,
}) => {
    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), {
        useSuspense: false,
    });
    const [selectedPlan, setSelectedPlan] = useState();
    const [showMorgisCheckoutModal, setShowMorgisCheckoutModal] =
        useState(false);
    const [country, setCountry] = useState("");

    const isBrazil = country === "Brazil";

    useLockBodyScroll();
    const onSubmit = () => {
        setShowMorgisCheckoutModal(true);
    };

    useLockBodyScroll();

    useEffect(() => {
        fetch("https://ipapi.co/json/")
            .then((response) => response.json())
            .then((data) => {
                setCountry(data.country_name);
            })
            .catch((error) =>
                console.error("Error fetching geolocation:", error)
            );
    }, []);

    return (
        <>
            {!showMorgisCheckoutModal && (
                <Popover
                    title={t("configure_membership_plans_modal.title", {
                        hostName,
                    })}
                    withHeader
                    onClose={onClose}
                    className={classes.modalRoot}
                    bodyClassName={classes.bodyClassName}
                >
                    <span className={classes.description}>
                        {t("configure_membership_plans_modal.description")}
                    </span>
                    <PlansList
                        isBrazil={isBrazil}
                        rookieId={rookieId}
                        selectedPlan={selectedPlan}
                        onSelectPlanHandler={(plan) => {
                            setSelectedPlan(plan);
                        }}
                    />
                    <Button
                        disabled={!selectedPlan}
                        className={classes.submit}
                        onClick={onSubmit}
                        data-primary
                    >
                        {t("configure_membership_plans_modal.submit")}
                    </Button>
                </Popover>
            )}

            {showMorgisCheckoutModal && (
                <MorgisCheckoutModal
                    onClose={() => {
                        setShowMorgisCheckoutModal(false);
                    }}
                    subscriptionPlan={selectedPlan}
                    paymentType={paymentTypes.subscription}
                    rookieId={rookieId}
                    rookieName={hostName}
                />
            )}
        </>
    );
};
export default SubscriptionsPlansModal;
