import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { createUseStyles } from "react-jss"
import Button from "../../../components/Button"
import { useViewportSize } from "../../../hooks/useViewportSize"
import { getAvailableNamespaces } from "../../../translations"
import { getAppLink, redirectToExternalLink, remapURLSearchParams } from "../../../utilities/utils"
import { APP_ROUTE_SIGNUP_ROOKIE_SOCIAL } from "../../../utilities/constants/appRouteNames"
import { PARAM_USER_GROUP } from "../../../utilities/constants"
import { getUserGroup } from "../../../utilities/constants/user"

const useStyles = createUseStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: 578,
        backgroundPosition: 'center',

    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: 8,
        alignItems: 'center',
        width: '100%'
    },
    content: {
        maxWidth: 548,
        width: '100%',
        paddingBottom: 24,
        "& > p": {
            margin: 0,
            textAlign: 'right',
        },
    },

    comment: {
        color: '#FFF',
        fontSize: 'clamp(20px, 3vw, 28px)',
        fontWeight: 300,
        lineHeight: '39px', /* 140% */
        letterSpacing: '2px',
        paddingBottom: 24
    },
    name: {
        color: 'rgba(255, 255, 255, 0.80)',
        fontSize: 'clamp(18px, 3vw, 20px)',
        fontWeight: '600',
        lineHeight: '120%', /* 24px */
    },
    job: {
        color: 'rgba(255, 255, 255, 0.80)',
        fontSize: 'clamp(16px, 3vw, 20px)',
        fontWeight: 300,
        lineHeight: '120%'
    },
    CTA: {
        width: '100%',
        height: '100%',
        maxHeight: 72,
        maxWidth: 454,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none !important',
        "& span": {
            fontSize: 'clamp(16px, 5vw, 24px)',
            fontWeight: 500,
            letterSpacing: '1px',
            textWrap: 'nowrap'
        }
    },
    '@media (max-width: 768px)': {
        container: {
            height: 667,
            backgroundPosition: 'unset',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        },
        comment: {
            lineHeight: '28px'
        },
        CTA: {
            maxWidth: '250px',
            width: '100%',
            border: 'none'
        },
    }
}))
const mobileWidth = 768 / 100
const UserCommentsSection = ({ role = 'main' }) => {
    const classes = useStyles()
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });
    const { vw } = useViewportSize()

    const [isMobile, setIsMobile] = useState(vw <= mobileWidth)

    const redirectToSignUp = () => {
        redirectToExternalLink(
            getAppLink(APP_ROUTE_SIGNUP_ROOKIE_SOCIAL, [
                ...remapURLSearchParams(location.search),
                {
                    name: PARAM_USER_GROUP,
                    value: getUserGroup(),
                },
            ]))
    }

    useEffect(() => {
        setIsMobile(vw <= mobileWidth)
    }, [vw])

    const content = {
        comment: `welcomeRookiePage.user_comments_section.${role}.comment`,
        name: `welcomeRookiePage.user_comments_section.${role}.name`,
        job: `welcomeRookiePage.user_comments_section.${role}.job`,
        bg: require(`../assets/userCommentsSection/${role}/${isMobile ? 'slideBg_mobile' : 'slideBg'}.png`)

    }

    return (
        <section
            style={{
                backgroundImage: `url(${content.bg})`
            }}
            className={classes.container}
        >
            <div className={classes.contentWrapper}>
                <div className={classes.content}>
                    <p className={classes.comment}>{t(content.comment)}</p>
                    <p className={classes.name}>{t(content.name)}</p>
                    <p className={classes.job}>{t(content.job)}</p>
                </div>
                <Button data-primary className={classes.CTA} onClick={redirectToSignUp}>{t("welcomeRookiePage.user_comments_section.CTA")}</Button>
            </div>
        </section>
    )
}
export default UserCommentsSection