import cx from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { MicromorgiIcon } from "../../../assets/icons/icons";
import Button from "../../../components/Button";
import Popover from "../../../components/Popover";
import { getAvailableNamespaces } from "../../../translations";
import MorgisCheckoutModal, {
    paymentTypes,
} from "../MorgisCheckoutModal/MorgisCheckoutModal";
import axios from "../../../utilities/axios";
import Spinner from "../../../components/Spinner";
import { useLockBodyScroll } from "../../../hooks/useLockBodyScroll";

const useStyles = createUseStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        minHeight: "100%",
    },
    modalBody: {
        padding: "0 24px 24px",
        maxWidth: "394px",
    },
    modalRoot: {
        width: "394px",
    },
    subtitle: {
        color: "#00063D",
        textAlign: "center",
        fontFamily: "Inter",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
        letterSpacing: "-1.1px",
    },
    descriptions: {
        color: "#00063D",
        textAlign: "center",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "140%" /* 19.6px */,
    },
    packagesList: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        gap: "24px",
        alignItems: "center",
        justifyContent: "center",
    },
    packageRoot: ({ isHasOnClick }) =>
        Object.assign(
            {
                width: "158px",
                height: "115px",
                flexShrink: "0",
                borderRadius: "10px",
                border: "2px solid #00063d33",
                background: "#FFF",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: "16px",
                gap: "8px",
                transition: "all 0.5s",
            },
            isHasOnClick
                ? {
                      cursor: "pointer",
                      "&:hover": {
                          backgroundColor: theme.colors.lightGrey,
                      },
                  }
                : {}
        ),
    packageRootActive: {
        backgroundColor: `${theme.colors.lightGrey} !important`,
    },
    packageRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    amountMorgis: {
        color: "#00063D",
        fontFamily: "Inter",
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "normal",
        letterSpacing: "-1.5px",
    },
    price: {
        color: "#00063D",
        textAlign: "center",
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "140%" /* 16.8px */,
    },
    packagesListContainer: {},
    submit: {
        width: "100%",
        marginTop: "auto",
    },
    "@media (max-width: 768px)": {
        modalRoot: {
            height: "100%",
            width: "100%",
            top: "0",
            left: "unset",
            transform: "none",
            borderRadius: 0,
        },
        modalBody: {
            margin: "0 auto",
        },
        packagesListContainer: {
            flex: "1",
        },
    },
}));

const MorgisPackagesModal = ({ onClose, rookieId, rookieName }) => {
    const [t] = useTranslation(getAvailableNamespaces(), {
        useSuspense: false,
    });
    const classes = useStyles({ isHasOnClick: false });
    const [showMorgisCheckoutModal, setShowMorgisCheckoutModal] =
        useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const onSubmit = () => {
        setShowMorgisCheckoutModal(true);
    };
    const [country, setCountry] = useState("");
    const [packagesList, setPackagesList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const isBrazil = country === "Brazil";
    const currencySymbol = isBrazil ? "R$" : "$";

    useLockBodyScroll();

    useEffect(() => {
        fetch("https://ipapi.co/json/")
            .then((response) => response.json())
            .then((data) => {
                setCountry(data.country_name);
            })
            .catch((error) =>
                console.error("Error fetching geolocation:", error)
            );
    }, []);

    useEffect(() => {
        const fetch = async () => {
            try {
                setIsLoading(true);
                const { data } = await axios.get(`/micromorgi-packages`);

                const sliceStart = country === "Brazil" ? 0 : 1;
                const sliceEnd = country === "Brazil" ? 4 : 5;

                const filteredMicromorgiPackagesByStatus = data
                    ?.filter(
                        (micromorgiPackage) =>
                            micromorgiPackage.level_status === "guest"
                    )
                    .slice(sliceStart, sliceEnd);
                setPackagesList(filteredMicromorgiPackagesByStatus);
            } catch (err) {
                console.log(err);
            } finally {
                setIsLoading(false);
            }
        };
        fetch();
    }, [country]);

    return (
        <>
            {!showMorgisCheckoutModal && (
                <Popover
                    onClose={onClose}
                    withHeader
                    title={t("morgis_packages_modal.title")}
                    className={classes.modalRoot}
                    bodyClassName={classes.modalBody}
                    minHeight={"650px"}
                >
                    <div className={classes.root}>
                        <h1 className={classes.subtitle}>
                            {t("morgis_packages_modal.subtitle")}
                        </h1>
                        <p className={classes.descriptions}>
                            {t("morgis_packages_modal.description")}
                        </p>
                        <div className={classes.packagesListContainer}>
                            {isLoading && <Spinner />}
                            <div className={classes.packagesList}>
                                {packagesList.map((packageItem) => (
                                    <PackageItem
                                        key={packageItem.id}
                                        onClick={() => {
                                            setSelectedPackage(packageItem);
                                        }}
                                        isBrazil={isBrazil}
                                        packageItem={packageItem}
                                        selectedPackage={selectedPackage}
                                    />
                                ))}
                            </div>
                        </div>

                        <Button
                            disabled={!selectedPackage}
                            className={classes.submit}
                            data-primary
                            onClick={onSubmit}
                        >
                            {selectedPackage
                                ? t("morgis_packages_modal.active_submit", {
                                      AMOUNT: selectedPackage.micromorgi_count,
                                      CURRENCY: currencySymbol,
                                      PRICE: selectedPackage.price,
                                  })
                                : t("morgis_packages_modal.submit")}
                        </Button>
                    </div>
                </Popover>
            )}

            {showMorgisCheckoutModal && (
                <MorgisCheckoutModal
                    onClose={() => {
                        setShowMorgisCheckoutModal(false);
                    }}
                    morgisPackage={selectedPackage}
                    paymentType={paymentTypes.micromorgi}
                    rookieId={rookieId}
                    selectedPackage={selectedPackage}
                    rookieName={rookieName}
                />
            )}
        </>
    );
};
export const PackageItem = ({
    onClick,
    packageItem,
    selectedPackage,
    isBrazil,
}) => {
    const classes = useStyles({ isHasOnClick: Boolean(onClick) });
    const [t] = useTranslation(getAvailableNamespaces(), {
        useSuspense: false,
    });
    const currencySymbol = isBrazil ? "R$" : "$";

    return (
        <div
            onClick={onClick}
            className={cx(
                classes.packageRoot,
                selectedPackage?.id === packageItem.id &&
                    classes.packageRootActive
            )}
        >
            <div className={classes.packageRow}>
                <MicromorgiIcon width={40} height={40} />
            </div>
            <div className={classes.packageRow}>
                <span className={classes.amountMorgis}>
                    {packageItem.micromorgi_count}
                </span>
                <span className={classes.price}>
                    {t("morgis_packages_modal.for", {
                        CURRENCY: currencySymbol,
                        PRICE: packageItem.price,
                    })}
                </span>
            </div>
        </div>
    );
};
export default MorgisPackagesModal;
