import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { getAvailableNamespaces } from "../../../translations";
import {
    HOSTS_TOUR_ARTISTS_SHOWBIZ_LINK,
    HOSTS_TOUR_ATHLETES_LINK,
    HOSTS_TOUR_ENTREPRENEURS_LINK,
    HOSTS_TOUR_INFLUENCERS_LINK,
    HOSTS_TOUR_LICENSED_PROFESSIONALS_LINK,
    HOSTS_TOUR_MODELS_LINK,
    HOSTS_TOUR_TEACHERS_COACHES_LINK,
    ROUTE_COMMUNITY_GUILDINES,
    ROUTE_COOKIE_POLICY,
    ROUTE_PRIVACY_POLICY,
    ROUTE_TOS_ROOKIE,
} from "../../../utilities/constants";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
    InstagramWhiteIcon,
    TiktokWhiteIcon,
} from "../../../assets/icons/icons";
import LogoMorgisLight from "../../../assets/images/morgisLogoLight3x.png";

const useStyles = createUseStyles((theme) => ({
    footer: {
        color: "white",
        background: "black",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "48px 0px",
        gap: "24px",
        "& > p": {
            textAlign: "center",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "700",
            lineHeight: "26px" /* 162.5% */,
            textTransform: "capitalize",
            display: "flex",
            gap: "2px",
            cursor: "default",
            userSelect: "none",
            "& > span": {
                color: "#8649D1",
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: "700",
                lineHeight: "26px",
                textTransform: "capitalize",
            },
        },
    },
    logo: {
        width: "164px",
    },
    policyLinksWrapper: {
        width: "100%",
        maxWidth: "954px",
        display: "flex",
        justifyContent: "space-between",
        "& > div": {
            display: "flex",
            justifyContent: "space-between",
            width: "40%",
            "& > a": {
                color: "#fff",
                textAlign: "center",
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "26px" /* 162.5% */,
                textTransform: "capitalize",
                textDecoration: "none",
            },
        },
    },
    linksToSpecialized: {
        width: "100%",
        maxWidth: "680px",
        display: "flex",
        justifyContent: "center",
        gap: "24px 48px",
        flexWrap: "wrap",
        "& > a": {
            color: "#fff",
            textAlign: "center",
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "26px" /* 162.5% */,
            textTransform: "capitalize",
            textDecoration: "none",
        },
    },
    socialMedias: {
        display: "flex",
        justifyContent: "center !important",
        gap: "48px",
        "& svg": {
            cursor: "pointer",
        },
    },
    "@media (max-width: 768px)": {
        linksToSpecialized: {
            maxWidth: "350px",
            gap: "12px 48px",
            "& > a": {
                fontSize: "12px",
            },
        },
        socialMedias: {
            gap: "24px",
            "& svg": {
                cursor: "pointer",
                transform: "scale(0.73)",
            },
        },
        logo: {
            width: "60px",
        },
        policyLinksWrapper: {
            maxWidth: "330px",
            "& > div": {
                flexDirection: "column",
                "& > a": {
                    fontSize: "12px",
                    textAlign: "left",
                },
            },
        },
    },
}));

const Footer = () => {
    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });

    return (
        <footer className={classes.footer}>
            <img
                src={LogoMorgisLight}
                alt={t("footer.logoAlt")}
                className={classes.logo}
            />
            <div className={classes.linksToSpecialized}>
                <Link to={HOSTS_TOUR_ATHLETES_LINK}>
                    {" "}
                    {t("menu.athletes")}{" "}
                </Link>
                <Link to={HOSTS_TOUR_ARTISTS_SHOWBIZ_LINK}>
                    {" "}
                    {t("menu.artists_&_showbiz")}{" "}
                </Link>
                <Link to={HOSTS_TOUR_MODELS_LINK}> {t("menu.models")} </Link>
                <Link to={HOSTS_TOUR_TEACHERS_COACHES_LINK}>
                    {" "}
                    {t("menu.teachers_&_coaches")}{" "}
                </Link>
                <Link to={HOSTS_TOUR_ENTREPRENEURS_LINK}>
                    {" "}
                    {t("menu.entrepreneurs")}{" "}
                </Link>
                <Link to={HOSTS_TOUR_INFLUENCERS_LINK}>
                    {" "}
                    {t("menu.influencers")}{" "}
                </Link>
                <Link to={HOSTS_TOUR_LICENSED_PROFESSIONALS_LINK}>
                    {" "}
                    {t("menu.licensed_professionals")}{" "}
                </Link>
            </div>
            <div className={classes.socialMedias}>
                <a
                    href="https://www.tiktok.com/@morgisocial"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <TiktokWhiteIcon />
                </a>
                <a
                    href="https://www.instagram.com/morgis.br/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <InstagramWhiteIcon />
                </a>
            </div>
            <div className={classes.policyLinksWrapper}>
                <div>
                    <Link to={ROUTE_TOS_ROOKIE}>
                        {t("footer.termsOfService")}
                    </Link>
                    <Link to={ROUTE_PRIVACY_POLICY}>
                        {t("footer.privacyPolicy")}
                    </Link>
                </div>
                <div>
                    <Link to={ROUTE_COMMUNITY_GUILDINES}>
                        {t("menu.community_guidelines")}
                    </Link>
                    <Link to={ROUTE_COOKIE_POLICY}>
                        {t("menu.cookie_policy")}
                    </Link>
                </div>
            </div>
            <p>
                {t("footer.copyright")}
                <span>{t("footer.allRightsReserved")}</span>
            </p>
        </footer>
    );
};
export default Footer;
