import cx from "classnames";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Button from "../../../components/Button";
import { getAvailableNamespaces } from "../../../translations";
import { PARAM_USER_GROUP } from "../../../utilities/constants";
import { APP_ROUTE_SIGNUP_ROOKIE_SOCIAL } from "../../../utilities/constants/appRouteNames";
import { getUserGroup } from "../../../utilities/constants/user";
import {
    getAppLink,
    redirectToExternalLink,
    remapURLSearchParams,
} from "../../../utilities/utils";
import tab1Gif from "../assets/moneyInMorgisSection/video1.gif";
import tab2Gif from "../assets/moneyInMorgisSection/video2.gif";
import tab3Gif from "../assets/moneyInMorgisSection/video3.gif";
import tab4Gif from "../assets/moneyInMorgisSection/video4.gif";

const useStyles = createUseStyles(() => ({
    container: {
        display: "flex",
        padding: [40, 8],
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 32,
        background:
            "linear-gradient(0deg, #E99701 0%, #F6B800 50%, #F9C642 100%)",
    },
    titleWrapper: {},
    title: {
        margin: 0,
        color: "#00063D",
        fontSize: "clamp(40px, 3vw, 72px)",
        fontWeight: "700",
        lineHeight: "110%",
    },
    subtitle: {
        margin: 0,
        color: "#00063D",
        textAlign: "center",
        fontSize: "clamp(40px, 3vw, 72px)",
        fontWeight: 400,
        lineHeight: "110%" /* 79.2px */,
    },
    tabsWrapper: {
        maxWidth: 736,
        maxHeight: 579,
        width: "100%",
        height: "100%",
        borderRadius: 14,
        background: "linear-gradient(180deg, #8649D1 0%, #7200FF 100%)",
        display: "flex",
        overflow: "hidden",
    },
    tabsList: {
        display: "flex",
        flexDirection: "column",
        width: "50%",
        flex: "1 0 auto",
    },
    tabsContent: {
        flex: "1 1 auto",
        padding: 8,
        background: "#FFF",
        "& img": {
            borderRadius: 12,
            width: "100%",
            height: "100%",
        },
    },

    CTA: {
        width: "100%",
        height: "100%",
        maxHeight: 72,
        maxWidth: 454,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "none !important",
        "& span": {
            fontSize: "clamp(16px, 5vw, 24px)",
            fontWeight: 500,
            letterSpacing: "1px",
            textWrap: "nowrap",
        },
    },
    "@media (max-width: 576px)": {
        tabsContent: {
            padding: [35, 8],
        },
        titleWrapper: {
            textAlign: "left",
            alignSelf: "flex-start",
        },
        subtitle: {
            textAlign: "left",
        },
        CTA: {
            maxWidth: "250px",
            width: "100%",
        },
    },
}));

const tabs = [
    {
        title: "welcomeRookiePage.moneyInMorgisSection.tabs.monthlySupportTab.title",
        subtitle:
            "welcomeRookiePage.moneyInMorgisSection.tabs.monthlySupportTab.description",
        content: tab1Gif,
    },
    {
        title: "welcomeRookiePage.moneyInMorgisSection.tabs.inChatTipsAndGiftsTab.title",
        subtitle:
            "welcomeRookiePage.moneyInMorgisSection.tabs.inChatTipsAndGiftsTab.description",
        content: tab2Gif,
    },
    {
        title: "welcomeRookiePage.moneyInMorgisSection.tabs.crowdFundingGoalsTab.title",
        subtitle:
            "welcomeRookiePage.moneyInMorgisSection.tabs.crowdFundingGoalsTab.description",
        content: tab3Gif,
    },
    {
        title: "welcomeRookiePage.moneyInMorgisSection.tabs.exclusiveContentTab.title",
        subtitle:
            "welcomeRookiePage.moneyInMorgisSection.tabs.exclusiveContentTab.description",
        content: tab4Gif,
    },
];

const MoneyInMorgisSection = () => {
    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });
    const [activeTab, setActiveTab] = useState(0);

    const redirectToSignUp = () => {
        redirectToExternalLink(
            getAppLink(APP_ROUTE_SIGNUP_ROOKIE_SOCIAL, [
                ...remapURLSearchParams(location.search),
                {
                    name: PARAM_USER_GROUP,
                    value: getUserGroup(),
                },
            ])
        );
    };

    return (
        <section className={classes.container}>
            <div className={classes.titleWrapper}>
                <Trans
                    t={t}
                    i18nKey={"welcomeRookiePage.moneyInMorgisSection.title"}
                    components={{
                        p: <p className={classes.subtitle} />,
                        h1: <h1 className={classes.title} />,
                    }}
                />
            </div>
            <div className={classes.tabsWrapper}>
                <div className={classes.tabsList}>
                    {tabs.map((tab, idx) => (
                        <Tab
                            key={idx}
                            onClick={() => {
                                setActiveTab(idx);
                            }}
                            index={idx}
                            activeIndex={activeTab}
                            tab={tab}
                        />
                    ))}
                </div>
                <div className={classes.tabsContent}>
                    {tabs.map((tab, idx) => (
                        <img
                            key={idx}
                            src={tab.content}
                            style={{ display: idx === activeTab ? "block" : "none" }}
                        />
                    ))}
                </div>
            </div>
            <Button
                data-primary
                className={classes.CTA}
                onClick={redirectToSignUp}
            >
                {t("welcomeRookiePage.moneyInMorgisSection.CTA")}
            </Button>
        </section>
    );
};

export default MoneyInMorgisSection;

const positions = {
    START: "start",
    CENTER: "center",
    END: "end",
};

const useTabStyles = createUseStyles(() => ({
    tab: ({ currentIndex, activeIndex }) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        flex: "1",
        cursor: "pointer",
        position: "relative",
        "& h1": {
            padding: 16,
            color: "#FFF",
            textAlign: "center",
            fontSize: "clamp(14px, 3vw, 24px)",
            fontWeight: 700,
            lineHeight: "120%" /* 28.8px */,
            margin: 0,
            ...(currentIndex === activeIndex - 1
                ? { width: "unset", borderBottom: "none", height: "unset" }
                : {
                    width: "90%",
                    borderBottom: "1px solid rgba(255, 255, 255, 0.30)",
                    height: "100%",
                }),
        },
        "& p": {
            color: "#8649D1",
            textAlign: "center",
            fontSize: "clamp(11px, 3vw, 18px)",
            fontWeight: 300,
            lineHeight: "125%" /* 22.5px */,
            margin: 0,
        },
        "&:last-child": {
            "& h1": {
                borderBottom: "none",
            },
        },
    }),
    activeTab: ({ position }) => ({
        padding: 16,
        background: "#fff",
        borderRadius: [12, 0, 0, 12],
        "& h1": {
            color: "#8649D1",
            width: "unset",
            borderBottom: "none",
            height: "unset",
            "@media (max-width: 576px)": {
                padding: 2,
            },
        },
        ...([positions.END, positions.CENTER].includes(position) && {
            "&::before": {
                content: "''",
                position: "absolute",
                top: "-40px",
                right: "0px",
                width: "40px",
                height: "40px",
                boxShadow: "white -6px -6px 0px 0",
                backgroundColor: "transparent",
                borderTopLeftRadius: "50%",
                transform: "rotate(180deg)",
            },
        }),
        ...([positions.START, positions.CENTER].includes(position) && {
            "&::after": {
                content: "''",
                position: "absolute",
                bottom: "-40px",
                right: "0px",
                width: "40px",
                height: "40px",
                boxShadow: "white -6px -6px 0px 0",
                backgroundColor: "transparent",
                borderTopLeftRadius: "50%",
                transform: "rotate(90deg)",
            },
        }),
    }),
    inactiveTab: {
        "& h1": {
            placeContent: "center",
        },
    },
}));
const Tab = ({ onClick, index, activeIndex, tab }) => {
    const isActiveTab = activeIndex === index;
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });
    const tabsListLength = tabs.length;
    const positionMap = {
        [0]: positions.START,
        [tabsListLength]: positions.END,
    };
    const classes = useTabStyles({
        position: positionMap[index] || positions.CENTER,
        currentIndex: index,
        activeIndex,
    });
    return (
        <div
            onClick={onClick}
            className={cx(
                classes.tab,
                isActiveTab ? classes.activeTab : classes.inactiveTab
            )}
        >
            <h1>{t(tab.title)}</h1>
            {isActiveTab && <p>{t(tab.subtitle)}</p>}
        </div>
    );
};
