import { Trans, useTranslation } from "react-i18next"
import { createUseStyles } from "react-jss"
import Button from "../../../components/Button"
import { getAvailableNamespaces } from "../../../translations"
import { PARAM_USER_GROUP } from "../../../utilities/constants"
import { APP_ROUTE_SIGNUP_ROOKIE_SOCIAL } from "../../../utilities/constants/appRouteNames"
import { getUserGroup } from "../../../utilities/constants/user"
import { getAppLink, redirectToExternalLink, remapURLSearchParams } from "../../../utilities/utils"

const useStyles = createUseStyles({
    container: {
        background: 'linear-gradient(0deg, #8649D1 0%, #8B6DE8 38.54%, #8F92FF 100%)',
        position: 'relative',
        padding: [26, 8]
    },
    lining: {
        position: 'absolute',
        backgroundImage: `url(${require("../assets/createConnectEarnSection/background.png")})`,
        left: 0,
        top: 0,
        height: '50%',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 24,
        padding: [210, 0, 0, 0],
        position: 'relative',
        zIndex: 1,
        '& h1': {
            margin: 0,
            color: '#FEB80C',
            textAlign: 'center',
            fontSize: 'clamp(53px, 3vw, 72px)',
            fontWeight: 500,
            letterSpacing: '12px',
            textTransform: 'uppercase',
            lineHeight: '72px'
        },
        "& p": {
            margin: 0,
            color: '#FFF',
            textAlign: 'center',
            fontSize: 'clamp(20px, 5vw, 24px)',
            fontWeight: 300,
            lineHeight: '30px', /* 125% */
            letterSpacing: 1.2,
            maxWidth: '548px',
            width: '100%',
            textAlign: 'center',
        }

    },
    CTA: {
        width: '100%',
        height: '100%',
        maxHeight: 72,
        maxWidth: 454,
        borderRadius: 21,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none !important',
        "& span": {
            background: "linear-gradient(0deg, #8649D1 0%, #8B6DE8 38.54%, #8F92FF 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontSize: 'clamp(16px, 5vw, 24px)',
            fontWeight: 500,
            letterSpacing: '1px',
            textWrap: 'nowrap'
        }
    },
    '@media (max-width: 576px)': {
        lining: {
            height: '60%',
        },
        content: {
            padding: [250, 0, 0, 0],
            '& h1': {
                letterSpacing: '10px',
                lineHeight: '59px'
            },
        },
        CTA: {
            maxWidth: '250px',
            width: '100%'
        },
    }
})

const CreateConnectEarnSection = () => {
    const classes = useStyles()
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });
    const redirectToSignUp = () => {
        redirectToExternalLink(
            getAppLink(APP_ROUTE_SIGNUP_ROOKIE_SOCIAL, [
                ...remapURLSearchParams(location.search),
                {
                    name: PARAM_USER_GROUP,
                    value: getUserGroup(),
                },
            ]))
    }
    return <section className={classes.container}>
        <div className={classes.lining} />

        <div className={classes.content}>
            <h1>
                <Trans
                    t={t}
                    i18nKey={'welcomeRookiePage.createConnectEarnSection.title'}
                />
            </h1>
            <p>{t('welcomeRookiePage.createConnectEarnSection.description')}</p>
            <Button onClick={redirectToSignUp} className={classes.CTA}>
                {t('welcomeRookiePage.createConnectEarnSection.CTA')}
            </Button>
        </div>
    </section>
}
export default CreateConnectEarnSection